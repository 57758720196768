.Footer {
   font-family: "Arial-Bold" Arial;

   font-size: 11px;

   overflow: auto;
}
.Footer a.App-link {
    color: #0F197D;
}
a.Footer-go-top:focus, a.Footer-go-top:hover{
    text-decoration: underline;
}
.Footer .MuiSvgIcon-root {
    font-size: 13px;
}
.Footer button.MuiIconButton-root  {
   font-size: 11px;
    color:#0f197d ;
}
.Footer .MuiIconButton-root:hover {
    background-color: #F2F2F2;
}
.Footer .MuiIconButton-label {
    font-weight: bold;
}

.Footer .footer-more {
    margin-top: .5rem;
    padding-bottom: 1.25rem;
}

.copyright {	
    float: left;
    padding: '.5%';
    padding-left:'1rem';
    background-color:'#bcbbc0';
    font-weight:'bold';
}

.version {
	margin: 0;
	font-size: .9em;
	padding: .1em 0 0 .25em;
	float: right;
}

@media screen and (max-width: 1500px) {

}