/* Branding Changes */
/* The Enlyte class is added to the body tag in public/index.html
*/
.Enlyte .MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #666666;
    border-width: 2px;
}

.Enlyte label.MuiFormLabel-root.Mui-focused {
    color: black;
}

.Enlyte .MuiFormLabel-asterisk,
.Enlyte .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
    color: #b0170c;
}

.Enlyte .MuiFormLabel-root.Mui-disabled .MuiFormLabel-asterisk,
.Enlyte .MuiFormLabel-root.Mui-disabled .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
    color: rgba(0, 0, 0, 0.38);
}

.Enlyte .MuiTableRow-head {
    background-color: #0F197D;
    color: white;
}

.Enlyte span.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: white;
}

.Enlyte th.MuiTableCell-head {
    color: white;
}

.Enlyte .MuiTableSortLabel-root.MuiTableSortLabel-active div {
    color: white;
}

.Enlyte .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active svg.MuiTableSortLabel-icon {
    color: white;
}

.Enlyte span.MuiTableSortLabel-root:hover {
    color: white;
}

.Enlyte tr.MuiTableRow-root:nth-child(even) {
    background-color: #f2f2f2 !important;
}
.Enlyte .MuiRadio-colorPrimary.Mui-checked{
    color:#049FBE;

}
.Enlyte .MuiCheckbox-colorSecondary.Mui-checked {
    color:#049FBE;
}
.Enlyte .MuiButton-containedPrimary {
    color: black;
    background-color: #00C8F0;
}

.Enlyte .MuiButton-containedPrimary:hover {
    background-color: #00B3D8;
}

.Enlyte .MuiButton-outlinedPrimary {
    color: black;
    border: 1px solid #00c8f0;
    background-color: rgba(0, 200, 240, 0.04);
}

.Enlyte .MuiButton-outlinedPrimary:hover {
    color: black;
    border: 1px solid #00c8f0;
    background-color: rgba(0, 200, 240, 0.04);
}

.Enlyte div.MuiPickersToolbar-toolbar {
    background-color: #0F197D;
}

.Enlyte button.MuiPickersDay-daySelected {
    background-color: #0F197D;
}

.Enlyte button.MuiPickersDay-daySelected:hover {
    background-color: #0F197D;
    text-decoration: underline;
}

.Enlyte button.MuiButton-containedPrimary {
    color: black;
    background-color: #00C8F0;
}

.Enlyte button.MuiButton-containedPrimary:hover {
    background-color: #00B3D8;
}

.Enlyte a, .Enlyte .App-link {
    color: #0F197D;
}

.Enlyte .Header .MuiIconButton-root .MuiIconButton-label {
    color: #0F197D;
}

.Enlyte .Footer button.MuiIconButton-root {
    color: #0F197D;
}
