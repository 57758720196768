/* Table Formatting Below */

.cvsTable  .MuiTableCell-root {
    border: 1px solid rgba(224, 224, 224, 1);
    font-size: 13px !important;
}

.cvsTable th.MuiTableCell-root {
    border-bottom: none;
}
.cvsTable th.MuiTableCell-root .filter-row {
    border-top:none;
}
.cvsTable .MuiTableCell-head {
    line-height: 1rem;
    padding: 0.5rem;
}
.cvsTable .Demographic tr.MuiTableRow-root:nth-child(even):hover {
    background-color: lightsteelblue !important;
    cursor: pointer;
}
.Demographic .MuiTableRow-hover:hover{
    background-color: lightsteelblue !important;
    cursor: pointer;
}

.cvsTable .MuiTypography-body2 {
    font-size: 13px;
    font-family: "Arial-Bold" Arial;
}
.cvsTable .MuiTableCell-paddingNone{
    padding-left: .5rem;
    padding-right: .5rem !important;
}

   th.cvsTable .MuiTableCell-root .MuiTableCell-paddingNone .MuiTableCell-sizeSmall .MuiTableCell-head {
   vertical-align: text-top;
    padding-bottom: 0!important;
       padding-top: 0!important;
}

.cvsTable div.MuiTablePagination-actions svg.MuiSvgIcon-root {
    fill: rgba(0, 0, 0, 0.57);
}
  
  .cvsTable tr.MuiTableRow-root:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
  .cvsTable  tr.MuiTableRow-root.filter-row{
    background-color: #fff !important;
}
  .cvsTable .MuiInputBase-root {
    font-size: 13px;
  }
  
  .cvsTable label + .MuiInput-formControl {
    margin-top: 0;
  }
  
  .cvsTable .MuiTableCell-paddingNone:last-child {
    padding-left: 0.5rem;
  }
  