
.Dlg-Button {
    background-color: #E9F6FC;
    color: #333333;
    font-size: 14px !important;
    font-family: "Arial-Bold" Arial !important;
    text-align: center;
    font-weight: bold;
    border: .1em solid #BCBEC0;
}
div.Dlg-button-row {
    text-align: center;
}
div.Dlg-button-spacer {
    width: 5%;
    display:inline-block;
}