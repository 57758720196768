.contentToggle {
}

.linkStyle {
    background: none;
    border: none;
    color: #0F197D;
    cursor: pointer;
    padding: 0;
    text-decoration: none;
    font-size: .75rem;
    margin-bottom: .5rem;
  }
  
  .linkStyle > span:hover {
    text-decoration: underline;
  }

.linkStyle:disabled,
.linkStyle[disabled] {
  opacity: 0.5;
  cursor: default;
}

.linkStyle:disabled > span:hover,
.linkStyle[disabled] > span:hover {
  text-decoration: none;
}