.registration {
  padding-top: 1rem;
}

.registration .verbage {
  font-size: 0.75rem;
}

.registration .verbage__agree {
  font-size: 0.75rem;
  font-weight: bold;
  margin-left: 0.35rem;
}

.registration .condition-list {
  padding-left: 1rem;
  list-style-position: outside;
}

.registration .verbage__condition {
  padding-top: 1rem;
}

.registration .theLabel-and {
  font-weight: bold;
  font-size: .75rem;
  margin-top: 1rem;
  margin-bottom: .5rem;
}

.registration .instructions {
  padding-top: .5rem;
 padding-bottom: .5rem;
  font-size: 0.85rem;
}

.registration .step-main {
  margin: 1rem 0 1.5rem 0;
}

.registration .theLabel-or {
  font-weight: bold;
  font-size: .75rem;
  text-align: center;
  margin: 0;
}

.registration .errorMessage {
  color: #B0170C;
  margin: 8px 14px 0;
  font-size: 0.75rem;

  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.03333em;  
}

.registration .loadingMessage {
  font-size: .8rem;
  padding: .1rem 0 0 .5rem;
  font-weight: bold;
  line-height: 1.5rem;
  display: inline-block;
  vertical-align: top;
}

.registration .loadingIndicator {
  line-height: 1.5rem;
  height: 1.5rem;
  margin: 0;
}

.registration .processingIndicator {
  line-height: 1.5rem;
  height: 1.5rem;
  margin: 0;
}

.registration .processingMessage {
  font-size: .8rem;
  padding: .1rem 0 0 .5rem;
  font-weight: bold;
  line-height: 1.5rem;
  display: inline-block;
  vertical-align: top;
}

.registration .passwordRequirements {
  font-size: .8125rem;
  align-self: stretch;
  padding-top: 1rem;
  margin-left: 1rem;
}

.registration .passwordRequirements h5 {
  font-size: .75rem;
  font-weight: bold;
  padding-left: 1.5rem;
  margin: 0;
}
.MuiCheckbox-colorPrimary.Mui-checked {

  color: #049FBE;
}