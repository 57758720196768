.Client-list {

}
.Client-list .MuiFormLabel-root {
    font-size: 13px;
    font-weight: 500;
    z-index: -1;
}
.Client-list .PrivateSwitchBase-input-92 {
    width: 30%;
    height: 30%;
}
.Client-list .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(10px, -15px) scale(1);
}
.Client-list .Client-list-select-div {

    padding-top: 1.7rem;
}
.Client-list .MuiTypography-body1 {
    font-size: 13px;
}
.Client-list .Client-list-error {
    font-size: 12px;
    margin-left: 8px;
}
body .Client-list {

}
.Client-list-input {
    width: 100%;

}
.Client-list + .MuiInput-formControl {
    margin-top: 0;
}
.Client-list  .MuiFormControl-root{
    font-size: 13px;
}
.Client-list  .MuiFormControl-root[style]{
    width:17.5rem !important;
}
.Client-list div.css-b1lnut-Control > div > div{
    top:60%;
}
.Client-list-line-error {
    padding-top: 1%;
    font-family: "Arial-Bold" Arial;
}
.Client-list-line {
    padding-top: 4%;
    font-family: "Arial-Bold" Arial;
}
.Client-list-line > label, .Client-list-line > span{

    vertical-align: -1em;
}

.Client-list-line > select{
    width: 60%;
}
.Client-list-buttons {
    padding-top: 1%;
    padding-bottom: 1%;
    width:30%;
    margin-left: 15%;

}
div.Client-list legend.Client-list-leg {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
    display: inline-block;
}
