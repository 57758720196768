.Login-view {
    padding-top: .5rem;
    min-height: 65vh;

}
.login-content {
    text-align:left;
    margin:auto;
}
.Login-div {
    border: .1em solid #BCBEC0;
    background-color: #ffffff;
    width: 34%;
    margin:auto;

}
.Login-content {
    padding-top: .5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}
.LoginComponent-inputs {
    width: 60%;
    -ms-user-select: none;
}

.App-Button:focus, .App-Button:hover {
    background-color:#0f197d;
    color: #fff;
}

:-ms-input-placeholder.mat-input-element {
    -ms-user-select: text !important;
}

.Submit-button-row {
    margin-left: 20%;
    padding-top:10%;
    font-size: 13px;
}
.Submit-button-row > span {
    padding-left: 4%;
}
.Blue-font {
    color: #0f197d;
}
.Last-login-row{
    text-align: center;
    padding-top: 5%;
    padding-bottom: 7%;
    font-size:13px;
}
.Last-login-row > span {
    padding-left: 2%;
}
.Font-italic {
    font-style: italic;
    font-family: Arial;
}
.Login-img-div {
    padding-top: 5%;
    height: 5em;
    width: 100%;
    text-align: center;
}
.Login-img-div  > img{
    padding-bottom: 5%;
    margin: auto;
}

.Login-usr-pwrd-label {
    display:inline-block;
    text-align:left;
    width:23%;
    font-weight: 700;
    padding-left:10%;
    color:#333333;
    padding-right:10%;

}
.Login-usr-pwrd-label > label {
    font-size:13px;
}
.Login-input-div > input {
    width:100%;
}
.Login-input-div{
    display:inline-block;
    width:35%;
}
.Login-error {
    font-size: 13px;
    padding-left: 1%;
    padding-right: 1%;
}
.Login-lock-error {
    color: #B0170C;

}
.Login-usr-pwrd-error{
    color: #B0170C;
}
.login-error-message{
    color:white;
    background-color: #B0170C;
    padding-left:.1em;
    font-weight: bold;
}
.dp-msg {
    margin: auto;
    textAlign: left;
    width: 75%;
}

.timeout-msg {
    background-color:  #b0170c;
    padding: .25rem .25rem .25rem .5rem;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: .7em;
    margin: auto;
    textAlign: left;
    width: 98%;
}
@media screen and (max-width: 1500px) {

    .Login-img-div-txt {

        font-size: 14px;

    }


    .Login-img-div > div {
        height: 2.5em;

        width: 80%;
        display:inline-block;
        margin: auto;
    }

    .Login-usr-pwrd-label  > label, Login-input-div > input[type=text]{
        font-size:10px;
    }

    .Login-usr-pwrd-div {

        font-size: 9;
    }
    .Submit-button-row, .Last-login-row {

        font-size: 9px;
    }
    .App-Button {

        font-size: 10px !important;
        font-family: "Arial-Bold" Arial !important;

    }
    .Last-login-row {
        font-size: 9px;
    }
    .Login-div-hdr {
        font-size: 11px;
    }
}
@media screen and (max-width: 1000px) {
    .Login-img-div-txt {
        vertical-align: 1.5rem;
        font-size:10px;

        font-weight:400;
        width:33%;
    }

    .Login-img-div > div {
        height: 2.5em;

        width: 80%;
        display:inline-block;
        margin: auto;
    }
    .Login-usr-pwrd-div {

        font-size:7;
    }
    .Submit-button-row,.Last-login-row {

        font-size: 7px;
    }
    .App-button{
        font-size: 8px !important;
        font-family: "Arial-Bold" Arial !important;
    }
    .Login-div-hdr {
        font-size: 9px;
    }

}