div.Password-req-pnl {
    line-height: 1;
    font-size: 13px;

    padding-left: 1rem;


}

div.Password-req-pnl > p:nth-child(1) {
    font-weight: bold;
}

div.Password-req {
    padding-left: 1em;
}

div.Password-req-pnl-err {
    line-height: 1;
    font-size: 13px;
    color:rgb(100, 0, 0);
    padding-left: 1rem;
}


