.navlink-menu {
    color: #fff;
    padding: 5px;
}

.WelcomePnl {
    width: 49%;
    color: #000066;
    font-size: 12px;
    height:230px;
    display: inline-block;
    margin-top: 10px;
    background-color: #cce6ff;
}
.WelcomePnl.WelcomePnl-welcome-pnl {
    width: 100%;
    height: 160px;
}

.home-grid-container {
    display: grid;
    grid-template-columns: auto;
    background-color:white;
    padding-right: 10px;
    padding-bottom: 5px;
    width: 100%;
}
.home-grid-item1 {
    background-color: rgba(255, 255, 255, 0.8);
    border: 0px solid rgba(0, 0, 0, 0.8);
    padding-top: 5px;
    padding-right: 20px;
    font-size: 12px;
    text-align: left;
    object-fit: cover;
    width:100%;
    height: 100%;

}
.home-grid-item2 {
    background-color: rgba(255, 255, 255, 0.8);
    border: 0px solid rgba(0, 0, 0, 0.8);
    font-size: 13px;
    text-align: left;
    width:100%;
    padding-right: 20px;
}

.iframe-container iframe {
    border: 0;
    left: 0;
    position: relative;
    top: 0;
    padding-bottom: 0;
    width: 100%;
    min-height: 900px;
}

iframe.wcppIFrame {
    border: 0;
    left: 0;
    position: relative;
    top: 0;
    padding-bottom: 0;
    width: 100%;
    overflow: hidden;
    height: auto;
    min-height: 900px;
}
