@charset "ISO-8859-1";

.main-menu {
  color: white;
  background-color: #0F197D;
  text-align: left;
}

a.mainMenuButton:hover {
  text-decoration: underline;
  color: white;
}

button.mainMenuButton:hover {
	text-decoration: underline;
	color: white;
  }
  