.dialogContent {
  padding: 0 1.5rem 0 1.5rem;
  width: 350px;
}

.dialogContent table {
  width: 100%;
  border-collapse: collapse;
}

.dialogContent th {
  text-align: left;
}

.dialogContent th,
.dialogContent td {
  border: 1px solid rgba(224, 224, 224, 1);
  font-size: 13px !important;
  padding: 0.5rem;
}

.dialogContent tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.paymentInfoDialog .MuiDialogActions-root {
  padding: 0.5rem 1rem 0.5rem 1rem !important;
}
