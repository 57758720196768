.feeSchedule .theLabel-or {
    font-weight: bold;
    font-size: .75rem;
    text-align: center;
    margin: 0;
  }
  
  .feeSchedule .errorMessage {
    color: #B0170C;
    margin: 8px 14px 0;
    font-size: 0.75rem;
  
    font-weight: 400;
    line-height: 1em;
    letter-spacing: 0.03333em;  
  }
  .feeSchedule .MuiGrid-spacing-xs-3 .MuiGrid-item {
      padding-bottom: 0;
  }
  .result-divider {
      border-bottom: 0.1em solid #bcbec0;
      padding-top: 4%;
  }
.Fee-schedule button.Mui-disabled{
   color: rgba(0, 0, 0, 0.26);
}

.Fee-schedule p, div.MuiSelect-root{
    color: #000000 ;
  }

  .feeSchedule .MuiTable-root{
    width: 700px;
  }

.feeSchedule  .MuiSvgIcon-colorPrimary {
    color: #049FBE;

}

