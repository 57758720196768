.Header-spacer {
   
    line-height: 200%;
}
.Header-icon-link:focus,  .Header-icon-link:hover{
    text-decoration: none;
}
a.Header-home-icon:focus {
   padding-left: 0;
    text-align: center;
}
.Header a.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary span.MuiBadge-root{
    color: #00C896;
}
.Header-login-logo {
    width: 100%;
    height: 100%;
}
button.MuiIconButton-root {
    padding: 0px;
}
.Header .MuiIconButton-root:hover{
   background-color: #F2F2F2;
}
/*div.LPMcontainer.LPMoverlay:not(:last-child) {
    display:none !important;
}
#PPLiveChat .LPMimage:last-child {
    display:inline-block !important;
}*/
div.LPMcontainer.LPMoverlay:last-child{
display:inline-block !important;
}

div.LPMcontainer.LPMoverlay{
    display:none !important ;
}
.Header .MuiIconButton-root .MuiIconButton-label{
    color:#0f197d;
}

.Header .MuiTextField-root{
    visibility: hidden;
}

.Header .profileLink {
    display: inline-block;
    padding: 0 .25rem 0 .25rem;
    position: relative;
    top: 2px;
}

@media screen and (max-width: 1000px) {
    .Header-spacer {

        line-height: 100%;
    }
    .Header-login-logo {
        width: 100%;
        height: 100%;
    }
}