.ManageTinsForUser > div{
  margin-top: 0;
}
.ManageTinsForUser .MuiTableCell-root {
    font-size: 13px!important;
}
.ManageTinsForUser .MuiGrid-item svg.MuiSvgIcon-root {

    color: #00C896;
}

.ManageTinsForUser th.MuiTableCell-root div.MuiInputBase-root{
    background-color: #ffffff;
}