.resetPassword {
  padding-top: 1rem;
}

.resetPassword .instructions {
  padding-top: .5rem;
  margin: 0;
  font-size: 0.85rem;
}

.resetPassword .step-main {
  margin: 1rem 0 1.5rem 0;
}

.align-self-center {
  flex: 0;
}

.resetPassword .red {
  color: #B0170C;
}

.resetPassword .bold {
  font-weight: bold;
}

.resetPassword .errorMessage {
  color: #B0170C;
  margin: 8px 14px 0;
  font-size: 0.75rem;

  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.03333em;  
}

.resetPassword .loadingMessage {
  font-size: .8rem;
  padding: .1rem 0 0 .5rem;
  font-weight: bold;
  line-height: 1.5rem;
  display: inline-block;
  vertical-align: top;
}

.resetPassword .loadingIndicator {
  line-height: 1.5rem;
  height: 1.5rem;
  margin: 0;
}

.resetPassword .processingIndicator {
  line-height: 1.5rem;
  height: 1.5rem;
  margin: 0;
}

.resetPassword .relative {
  position: relative;
}

.resetPassword .processingMessage {
  font-size: .8rem;
  padding: .1rem 0 0 .5rem;
  font-weight: bold;
  line-height: 1.5rem;
  display: inline-block;
  vertical-align: top;
}

.resetPassword .passwordRequirements {
  font-size: .8125rem;
  padding-top: 1rem;
}

.resetPassword .passwordRequirements h5 {
  font-size: .75rem;
  font-weight: bold;
  padding-left: 1.5rem;
}
