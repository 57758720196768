.ClntLstResponse {
    margin-top: -2rem;
}

body .ClntLstResponse{
    line-height: 1 ;
    font-size: 13px;
}

input.text-filter.form-control {
    line-height: 1 ;
    font-size: 13px;
}
a.page-link {
    color: #0f197d ;
}
 .ClntLstResponse-border{
    border:1px solid rgba(224, 224, 224, 1);
    width: 100%;

}
.ClntLstResponse .MuiTableRow-root {
    vertical-align: bottom;
}

