.Provider-resources {

}

.Provider-resources-tbl-hdr {

}
.Provider-resources .MuiTableCell-root {
    font-size: 13px!important;
    height:35px;
}
.Provider-resources .MuiInputBase-root {
    font-size: 13px;
}
.Provider-resources label + .MuiInput-formControl {
    margin-top: 0;
}

.Provider-resources-slct-lbl .MuiFormLabel-root {
    color:#0f197d;
}
.Provider-resources .MuiTableCell-paddingNone:last-child {
    padding-left: .5rem;
}

.Provider-resources #category-label {
    background: white;
    padding-right: .25rem;
}