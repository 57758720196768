.confirmationDialog {
    width: 450px;
}

.confirmationDialog .dialogContent {
    padding: 0 1.5rem 0 1.5rem;
  }
    
  .confirmationDialog .MuiDialogActions-root {
    padding: 0.75rem 1rem 0.5rem 1rem !important;
  }

  .confirmationDialog p {
      padding-top: 0;
      margin-top: 0;
  }
  