
div.Change-Password {
   text-align: left;
    padding: 2%;
}
.Change-Password-input-pnl label{
    font-weight: bold;
}

.Change-Password-input-pnl {
    width: 100%;
    display: grid;

/*need to use material grid fro columns because of IE:    grid-template-columns: 25% 40%;*/

    grid-row-gap: 1em;
}
.Change-Password-input-pnl > div.MuiGrid-container > div.MuiGrid-item:nth-child(1) {
    width: 15%;
}
.Change-Password-input-pnl > div.MuiGrid-container > div.MuiGrid-item:nth-child(2) {
    width: 40%;
}
.Change-Password-input-pnl > div.MuiGrid-container > div.MuiGrid-item:nth-child(2) > input {
   width:100%;
}
div.Change-Password > div.App-error-font {
    padding-bottom: 2%;
}
div.Password-req .MuiSvgIcon-colorPrimary{
    color:#049fbe;
}

div.Change-Password-btn-row {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
}
.Change-Password-btn-filler{
   width:3%;
    display: inline-block;
}

