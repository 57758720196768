.Manage-tins {
}
.Manage-tins-divider {
    border-bottom: 0.1em solid #bcbec0;
    padding-top: 3%;
}
.Manage-tins > h1 {
  margin-bottom: 0;
}
.Manage-tins-btn-ln {
    padding-top: 1.5%;
}
.Manage-tins-btn-ln-spacer {
    display: inline-block;
    width: 4%;
}
th.MuiTableCell-head {
    color: #0f197d;
}
.Manage-tins div.MuiFormControl-root {
    width: 30%;
}
.Manage-tins .MuiTableCell-root {
    font-size: 13px!important;
}