.billSearch .theLabel-or {
    font-weight: bold;
    font-size: .75rem;
    text-align: center;
    margin: 0;
  }
  
  .billSearch .errorMessage {
    color: #B0170C;
    margin: 8px 14px 0;
    font-size: 0.75rem;
  
    font-weight: 400;
    line-height: 1em;
    letter-spacing: 0.03333em;  
  }
  .billSearch .MuiGrid-spacing-xs-3 .MuiGrid-item {
      padding-bottom: 0;
  }
  .result-divider {
      border-bottom: 0.1em solid #bcbec0;
      padding-top: 3%;
  }

  .billSearch .eor-pricing-message {
    font-size: 13px;
    padding: 0 .25rem 0 .25rem;
  }

  .billSearch .billSearchHelp {
    border: 1px solid lightgray;
    border-radius: 6px;
  }

  .billSearch .billSearchHelp {
    border: 1px solid lightgray;
    border-radius: 6px;
    padding: 1rem;
    font-size: .85rem;
    margin-bottom: .5rem;
  }

  .billSearch .billSearchHelp > h4 {
    font-size: 16px;
  }
  
  .billSearch .billSearchHelp > ul {
    margin: 0;
    padding: 0 0 0 1.5rem;
  }
