.App {
  width: 90%;
  min-height: 90%;
  margin: auto;
}
div.MuiTooltip-tooltipPlacementBottom {
  font-style: normal;
  font-size: 12px;
  margin: 6px 0;
}

.App-content .MuiTableCell-root {
  border:1px solid rgba(224, 224, 224, 1);
}
.App-content .MuiFormControl-marginDense {
  margin-top: 0;
  margin-bottom: 0;
}
a, .App-link {
  color: #0F197D;
}
.App-content .MuiTypography-body2 {
  font-size: 13px;
  font-family: "Arial-Bold" Arial;
}
.App-content th.MuiTableCell-root {
  border-bottom: none;
}
.App-content th.MuiTableCell-root .filter-row {
  border-top:none;
}

.App-content div.MuiTablePagination-actions svg.MuiSvgIcon-root {
  fill:rgba(0, 0, 0, 0.57);  
}
div.MuiTablePagination-root .MuiIconButton-root, 
.MuiTableFooter-root .MuiTablePagination-root .MuiIconButton-root{ 
  /*color:#0f197d;*/
}
div.MuiTablePagination-root .MuiIconButton-root.Mui-disabled,
.MuiTableFooter-root .MuiTablePagination-root .MuiIconButton-root.Mui-disabled { 
  color: rgba(0, 0, 0, 0.26);
}
.App-content tr.MuiTableRow-root:nth-child(even){
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.App-content tr.MuiTableRow-root.filter-row.MuiTableRow-head:nth-child(even) {
  background-color: #fff !important;
}
.App-content .MuiTableCell-paddingNone{
  padding-left: .4em;
}
.App-content {
  min-height: 55vh;
}
.App-error-color {
  color: #b0170c;
}
.App-error-font {
  font-family: "Arial-Bold" Arial;
  font-size: 13px;
  color: #B0170C;
}
.App-font-light-color {
  color: #ffffff;
}
.App-font-color {
  color: #333333;
}
.App-font-bold {
  font-weight: 700;
}
.body [font-family] {
  font-family: "Arial-Bold" Arial;
  font-size: 13px;
}
.App-font {
  font-family: "Arial-Bold" Arial;
  font-size: 13px;
}
.App-error {
  background-color: #b0170c;
  color: #ffffff;
  width: 100%;
}
.App-success {
  background-color: rgba(94, 151, 49, 1);
  color: #ffffff;
  width: 100%;
}
.App-Button {
  background-color: #e9f6fc;
  color: #333333;
  font-size: 14px;
  height: 1.8em;
  padding-left: 1em;
  padding-right: 1em;
  font-family: "Arial-Bold" Arial;
  text-align: center;
  font-weight: bold;
  border: 0.1em solid #bcbec0;
}

.App-Button:focus:enabled,
.App-Button:hover:enabled {
  background-color: #0f197d ;
  color: #fff;
}
.App-page-label {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: .5rem;
  margin-top: 0;
}
h1.App-page-label{
  margin-bottom: 0;
}
.App-note-Field {
  padding-top:.5rem;
  padding-bottom: 1rem;
  margin:0;
}

.App-Button:disabled,
.App-Button:hover:disabled,
.App-Button[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.App-ltgrey-bkgrnd {
  background-color: #F2F2F2;
}

.App-link {
  text-decoration: none;
}
.App-link:focus,
.App-link:hover {
  text-decoration: underline;
}


/* fix!? for IE not handling flexbox column */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  div[class*="column"].MuiGrid-container > .MuiGrid-item
   {
    flex-basis: auto;
  }
}

.App .legend {
  font-weight: 500;
  padding-top: 1.5rem;
}

.App fieldset {
  display: block;
  width: 100%;
}

.pad-bottom-75 {
  padding-bottom: .75rem;
}

.pageTitle {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: .5rem;
}
h1.pageTitle{
  margin-bottom: 0;
}

.centerBox-vertical {
  display: flex;
  align-items: center;
}

.centerItem-vertical {
  align-self: center;
  margin-bottom: 0;
  margin-top: 1px;
}

.globalErrorMessage {
  background-color:  #b0170c;
  padding: .5rem .5rem .5rem .75rem;
  color: #fff;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1em;
}
.registration .globalErrorMessage {
  width: 70rem;
}
.App-dp-msg {
  background-color:  #b0170c;
  padding: .25rem .25rem .25rem .75rem;
  color: #fff;

  font-size: 14px;
  font-weight: 400;
  line-height: 1em;
}

.globalSuccessMessage {
  background-color: #00C896;
  padding: .5rem .5rem .5rem .75rem;
  color: #000000;

  font-size: 14px;
  font-weight: 400;
  line-height: 1em;
}

.App-loading-div {
  width: 100%;
  padding: .5rem .5rem .5rem .75rem;
  background-color: #E9F6Fc;
  color: #0f197d ;
  font-size: 14px;
  font-weight: 400;

}

.App-loading-div > span{
  padding-left: 1em;
  vertical-align: .25em;
}

.main {
  padding: 1rem;
}

.align-self-center {
  flex: 0;
  align-self: center;
}

.red {
  color: #B0170C;
}

.bold {
  font-weight: bold;
}

.instructions {
  padding-top: .5rem;
  margin: 0;
  font-size: 0.85rem;
}

.buttonBar {
  padding-top: 1rem;
}
.processingIndicator {
  line-height: 1.5rem;
  height: 1.5rem;
  margin: 0;
}

.processingMessage {
  font-size: .8rem;
  padding: .1rem 0 0 .5rem;
  font-weight: bold;
  line-height: 1.5rem;
  display: inline-block;
  vertical-align: top;
}
a.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary:hover{
  text-decoration: underline;
  cursor: pointer;
}
a.MuiTypography-root.MuiLink-root.MuiLink-underlineHover.MuiTypography-colorPrimary{
  color: #0f197d !important;
}

.message-no-results-found {
  padding: 1rem 0 0 .5rem;
}
div.MuiPaper-root.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-rounded{
    box-shadow: none !important;
}
