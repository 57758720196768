.Manage-users .MuiTableCell-root {
    font-size: 13px!important;
}
.Manage-users .MuiInputBase-root {
    font-size: 13px;
}
.Manage-users label + .MuiInput-formControl {
    margin-top: 0;
}

.Manage-users-slct-lbl .MuiFormLabel-root {
    color: #0f197d;
    line-height: 2rem;
}
.Manage-users .MuiTableCell-paddingNone:last-child {
    padding-left: .5rem;
}

.Manage-users-tbl-div > div{
    margin-top: 0;
}

.Manage-users .MuiGrid-spacing-xs-3 .MuiGrid-item {
    padding-bottom: 0;
}
.result-divider {
    border-bottom: 0.1em solid #bcbec0;
    padding-top: 3%;
}
.link-disabled {
    color: currentColor;
    pointer-events: none;
    opacity: 0.5;

}