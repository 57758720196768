h5, h6 {
    font-weight: 600;
}
div.Primary-indicator {
    display: inline-block;
    vertical-align: +.2rem;
    padding-right: .2em;
}
div.Primary-indicator > .MuiAvatar-root {
    width: 1rem;
    height:1rem;
    font-size: .6rem;

}
div.Primary-indicator > .MuiAvatar-colorDefault {
    background-color: #0f197d;
}
